/* Forked from https://github.com/Coffeedon/Cofffee-Assets/blob/main/styles/modern/style.scss */

:root {
  --tl-width: 750px;
  --radius: 12px;
  --radius-round: 24px;
  --hover-color: rgba(170, 170, 170, 7%);
  --border-color: rgba(120, 120, 120, 20%);
  --shadow: 0 10px 40px -10px rgba(0, 0, 0, 15%);
  --shadow-low: 0 8px 16px -10px rgba(0, 0, 0, 40%);
  --shadow-med: 0 8px 60px -30px rgba(0, 0, 0, 10%);
}

.rtl textarea,
.rtl p {
  text-align: right;
  direction: rtl;
}

#mastodon .button--block,
.public-layout .button--block {
  font-weight: 700;
}

#mastodon .compose-form__upload-thumbnail,
.public-layout .compose-form__upload-thumbnail,
#mastodon button:not(.column-header__button),
.public-layout button:not(.column-header__button),
#mastodon video,
.public-layout video,
#mastodon input,
.public-layout input,
#mastodon iframe,
.public-layout iframe,
#mastodon .privacy-dropdown__dropdown,
.public-layout .privacy-dropdown__dropdown,
#mastodon .react-toggle-track,
.public-layout .react-toggle-track,
#mastodon .reply-indicator,
.public-layout .reply-indicator,
#mastodon .compose-form__warning,
.public-layout .compose-form__warning {
  border-radius: var(--radius) !important;
}

#mastodon button:focus-visible,
.public-layout button:focus-visible,
#mastodon .focusable:focus-visible,
.public-layout .focusable:focus-visible,
#mastodon a:focus-visible,
.public-layout a:focus-visible,
#mastodon .media-gallery__item-thumbnail:focus-visible,
.public-layout .media-gallery__item-thumbnail:focus-visible {
  box-shadow: inset 0 0 0 2px #dc7b18;
  outline: 2px #dc7b18 solid;
  outline-offset: -2px;
}

#mastodon *,
.public-layout * {
  border-color: var(--border-color) !important;
  font-display: swap !important;
}

#mastodon .media-gallery__item,
.public-layout .media-gallery__item,
#mastodon .column-link,
.public-layout .column-link,
#mastodon select,
.public-layout select,
#mastodon .status-card,
.public-layout .status-card,
#mastodon .audio-player,
.public-layout .audio-player {
  transition: transform 0.4s cubic-bezier(0, 0, 0, 3) !important;
}

#mastodon .media-gallery__item:active,
.public-layout .media-gallery__item:active,
#mastodon .column-link:active,
.public-layout .column-link:active,
#mastodon select:active,
.public-layout select:active,
#mastodon .status-card:active,
.public-layout .status-card:active,
#mastodon .audio-player:active,
.public-layout .audio-player:active {
  transform: scale(0.99);
  transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
}

#mastodon .react-toggle-track,
.public-layout .react-toggle-track,
#mastodon .button,
.public-layout .button,
#mastodon .icon-button,
.public-layout .icon-button,
#mastodon .floating-action-button,
.public-layout .floating-action-button {
  transition: transform 0.4s cubic-bezier(0, 0, 0, 4) !important;
}

#mastodon .react-toggle-track:active,
.public-layout .react-toggle-track:active,
#mastodon .button:active,
.public-layout .button:active,
#mastodon .icon-button:active,
.public-layout .icon-button:active,
#mastodon .floating-action-button:active,
.public-layout .floating-action-button:active {
  transform: scale(0.95);
  transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
}

#mastodon .status__content__spoiler-link span,
.public-layout .status__content__spoiler-link span {
  display: inline-block;
  transition: transform 0.4s cubic-bezier(0, 0, 0, 4) !important;
}

#mastodon .status__content__spoiler-link:active span,
.public-layout .status__content__spoiler-link:active span {
  transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
  transform: translateY(1px);
}

#mastodon .react-toggle-thumb,
.public-layout .react-toggle-thumb {
  pointer-events: none;
}

#mastodon .account__avatar,
.public-layout .account__avatar,
#mastodon #profile_page_avatar,
.public-layout #profile_page_avatar {
  border-radius: 30% !important;
}

#mastodon .detailed-status__action-bar,
.public-layout .detailed-status__action-bar,
#mastodon .column-header__collapsible-inner,
.public-layout .column-header__collapsible-inner,
#mastodon .compose-form__buttons-wrapper,
.public-layout .compose-form__buttons-wrapper,
#mastodon .audio-player,
.public-layout .audio-player,
#mastodon .box-widget input[type='email'],
.public-layout .box-widget input[type='email'],
#mastodon .box-widget input[type='password'],
.public-layout .box-widget input[type='password'],
#mastodon .box-widget input[type='text'],
.public-layout .box-widget input[type='text'],
#mastodon .box-widget textarea,
.public-layout .box-widget textarea,
#mastodon .compose-form .spoiler-input__input,
.public-layout .compose-form .spoiler-input__input,
#mastodon .compose-form__autosuggest-wrapper,
.public-layout .compose-form__autosuggest-wrapper,
#mastodon .compose-form__poll-wrapper select,
.public-layout .compose-form__poll-wrapper select,
#mastodon .poll__option input[type='text'],
.public-layout .poll__option input[type='text'],
#mastodon .report-dialog-modal__textarea,
.public-layout .report-dialog-modal__textarea,
#mastodon .search__input,
.public-layout .search__input,
#mastodon .setting-text,
.public-layout .setting-text,
#mastodon .statuses-grid .detailed-status,
.public-layout .statuses-grid .detailed-status {
  border: 0 !important;
}

#mastodon .columns-area__panels__pane__inner,
.public-layout .columns-area__panels__pane__inner {
  margin-top: 30px;
}

#mastodon .compose-panel,
.public-layout .compose-panel {
  margin-top: 0;
}

#mastodon .search,
.public-layout .search,
.search-popout {
  margin-inline: 5px;
  border-radius: var(--radius);
}

#mastodon .search label,
.public-layout .search label {
  box-sizing: border-box;
}

#mastodon .search input,
.public-layout .search input {
  border-radius: var(--radius-round) !important;

  // padding-inline-start: 20px !important;
  // padding-inline-end: 40px !important;
}

#mastodon .search .search__icon > i,
.public-layout .search .search__icon > i {
  margin-inline: 5px;
}

#mastodon .compose-form .reply-indicator__display-name,
.public-layout .compose-form .reply-indicator__display-name {
  padding: 0;
}

#mastodon .compose-form .compose-form__autosuggest-wrapper,
.public-layout .compose-form .compose-form__autosuggest-wrapper,
#mastodon .compose-form .autosuggest-textarea__textarea,
.public-layout .compose-form .autosuggest-textarea__textarea {
  border-radius: var(--radius) var(--radius) 0 0 !important;
}

#mastodon .compose-form .compose-form__buttons-wrapper,
.public-layout .compose-form .compose-form__buttons-wrapper {
  border-radius: 0 0 var(--radius) var(--radius) !important;
}

#mastodon .compose-form .compose-form__publish-button-wrapper,
.public-layout .compose-form .compose-form__publish-button-wrapper {
  overflow: visible !important;
}
@media (width >= 895px) {
  .layout-single-column .getting-started__footer {
    opacity: 0.5;
    transition: opacity 0.4s;
  }

  .layout-single-column .getting-started__footer a {
    text-decoration: none;
  }

  .layout-single-column .getting-started__footer > p {
    font-size: 0;
    line-height: 0;
    opacity: 0;
    transition:
      font-size 0.2s,
      line-height 0.3s cubic-bezier(1, 0, 0, 0);
    transition-duration: 0.2s;
  }

  .layout-single-column .getting-started__footer:hover,
  .layout-single-column .getting-started__footer:focus-within {
    opacity: 1;
  }

  .layout-single-column .getting-started__footer:hover > p,
  .layout-single-column .getting-started__footer:focus-within > p {
    font-size: 1em;
    line-height: 1.5;
    opacity: 1;
    transform: translateY(0);
    transition:
      font-size 0.1s,
      line-height 0.3s cubic-bezier(0.8, 0, 0, 1.2),
      opacity 0.2s 0.1s;
  }
}

#mastodon .columns-area__panels__main,
.public-layout .columns-area__panels__main {
  // margin: 0 10px;
  padding-top: 30px !important;
  overflow: visible !important;
  transition:
    max-width 0.2s cubic-bezier(0, 0, 0, 1.1),
    margin 0.2s cubic-bezier(0, 0, 0, 1.1);
}
@media (width >= 1320px) {
  #mastodon .columns-area__panels__main,
  .public-layout .columns-area__panels__main {
    max-width: var(--tl-width) !important;
    padding: 0 15px;
  }
}

#mastodon .columns-area__panels__main > div,
.public-layout .columns-area__panels__main > div {
  border-radius: var(--radius);
}

#mastodon .columns-area__panels__main > div:last-child,
.public-layout .columns-area__panels__main > div:last-child {
  box-shadow: var(--shadow-med);
}

#mastodon .columns-area__panels__main .scrollable,
.public-layout .columns-area__panels__main .scrollable {
  padding-bottom: 40vh !important;
}

#mastodon .scrollable,
.public-layout .scrollable,
#mastodon .column-back-button,
.public-layout .column-back-button,
#mastodon .column-header,
.public-layout .column-header {
  border: 0;
  border-radius: var(--radius);
}

#mastodon .scrollable:not(.scrollable--flex),
.public-layout .scrollable:not(.scrollable--flex),
#mastodon .activity-stream,
.public-layout .activity-stream {
  border: 0 !important;
  contain: unset !important;
}

#mastodon .scrollable:not(.scrollable--flex, .activity-stream),
.public-layout .scrollable:not(.scrollable--flex, .activity-stream),
#mastodon .activity-stream:not(.activity-stream),
.public-layout .activity-stream:not(.activity-stream) {
  padding: 10px;
}

.layout-multiple-columns {
  #mastodon .scrollable:not(.scrollable--flex, .activity-stream),
  .public-layout .scrollable:not(.scrollable--flex, .activity-stream),
  #mastodon .activity-stream:not(.activity-stream),
  .public-layout .activity-stream:not(.activity-stream) {
    padding-right: 0;
  }
}

#mastodon
  .scrollable:not(.scrollable--flex)
  > [tabindex]:first-child
  > .focusable,
.public-layout
  .scrollable:not(.scrollable--flex)
  > [tabindex]:first-child
  > .focusable,
#mastodon .activity-stream > [tabindex]:first-child > .focusable,
.public-layout .activity-stream > [tabindex]:first-child > .focusable {
  margin-top: 0;
}

#mastodon
  .scrollable:not(.scrollable--flex)
  > div
  > [tabindex]:last-child
  .status,
.public-layout
  .scrollable:not(.scrollable--flex)
  > div
  > [tabindex]:last-child
  .status,
#mastodon .activity-stream > div > [tabindex]:last-child .status,
.public-layout .activity-stream > div > [tabindex]:last-child .status {
  border: 0;
}

#mastodon .column,
.public-layout .column,
#mastodon .columns-area,
.public-layout .columns-area {
  overflow: visible !important;
}

#mastodon .scrollable--flex .account-timeline__header,
.public-layout .scrollable--flex .account-timeline__header {
  margin: 0 !important;
}

#mastodon .item-list,
.public-layout .item-list {
  background-color: inherit;
  border-radius: var(--radius);
}

#mastodon .account-timeline__header,
.public-layout .account-timeline__header {
  margin: -10px;
  margin-bottom: 10px;
  background-color: inherit;
  border-radius: var(--radius);
}

.layout-multiple-columns {
  #mastodon .account-timeline__header,
  .public-layout .account-timeline__header {
    margin-right: 0;
  }
}

#mastodon .account-timeline__header .account__moved-note,
.public-layout .account-timeline__header .account__moved-note {
  box-sizing: border-box;
  border-radius: var(--radius);
  margin-bottom: calc(0px - var(--radius));
  padding: 30px;
  padding-bottom: calc(var(--radius) + 30px);
  background: inherit;
}

#mastodon
  .account-timeline__header
  .account__moved-note
  .detailed-status__display-name,
.public-layout
  .account-timeline__header
  .account__moved-note
  .detailed-status__display-name {
  overflow: visible !important;
}

#mastodon .account-timeline__header .account__header,
.public-layout .account-timeline__header .account__header {
  overflow: visible !important;
}

#mastodon .account-timeline__header .account__header__info,
.public-layout .account-timeline__header .account__header__info {
  z-index: 2;
}

#mastodon .account-timeline__header .account__header__image,
.public-layout .account-timeline__header .account__header__image {
  height: 0;
  padding-bottom: 35%;
  position: sticky;
  top: calc(0px - var(--radius));
  border-radius: var(--radius) var(--radius) 0 0;
}

#mastodon .account-timeline__header .account__header__image img,
.public-layout .account-timeline__header .account__header__image img {
  position: absolute;
}

#mastodon .account-timeline__header .account__header__bar,
.public-layout .account-timeline__header .account__header__bar {
  padding: 10px;
  border: 0;
  border-radius: var(--radius) var(--radius) 0 0;
  margin-top: calc(0px - var(--radius)) !important;
  background: lighten($ui-base-color, 4%);
}

#mastodon .account-timeline__header .account__header__tabs__name h1,
.public-layout .account-timeline__header .account__header__tabs__name h1 {
  display: flex;
  flex-wrap: wrap;
}

#mastodon .account-timeline__header .account__header__tabs__name h1 span,
.public-layout .account-timeline__header .account__header__tabs__name h1 span {
  margin-right: 0.4em;
}

#mastodon .account-timeline__header .account-role,
.public-layout .account-timeline__header .account-role {
  margin-right: 0.4em;
}

#mastodon .account-timeline__header .account-role > span,
.public-layout .account-timeline__header .account-role > span {
  margin: 0 !important;
}

#mastodon .account-timeline__header .account__header__bio,
.public-layout .account-timeline__header .account__header__bio {
  margin: 0;
  margin-top: 10px;
}

/*
#mastodon .account-timeline__header .account__header__bio > div,
.public-layout .account-timeline__header .account__header__bio > div {
  // padding-inline: 10px !important;
}
*/

#mastodon .account__header__fields,
.public-layout .account__header__fields,
#mastodon .account__header__account-note,
.public-layout .account__header__account-note {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 0;
  gap: 10px;
}

.public-account-bio .account__header__fields {
  padding: 20px !important;
  padding-bottom: 0 !important;
}

#mastodon .account__header__fields dl,
.public-layout .account__header__fields dl,
#mastodon .account__header__account-note dl,
.public-layout .account__header__account-note dl,
#mastodon
  .account__header__fields.account__header__account-note.account__header__account-note,
.public-layout
  .account__header__fields.account__header__account-note.account__header__account-note,
#mastodon
  .account__header__account-note.account__header__account-note.account__header__account-note,
.public-layout
  .account__header__account-note.account__header__account-note.account__header__account-note {
  border: 0;
  border-radius: var(--radius-round);
  overflow: hidden;
  background-color: var(--border-color);

  // padding: 0 !important;
}

#mastodon .account__header__fields dl dt,
.public-layout .account__header__fields dl dt,
#mastodon .account__header__account-note dl dt,
.public-layout .account__header__account-note dl dt,
#mastodon
  .account__header__fields.account__header__account-note.account__header__account-note
  dt,
.public-layout
  .account__header__fields.account__header__account-note.account__header__account-note
  dt,
#mastodon
  .account__header__account-note.account__header__account-note.account__header__account-note
  dt,
.public-layout
  .account__header__account-note.account__header__account-note.account__header__account-note
  dt,
#mastodon .account__header__fields dl dd,
.public-layout .account__header__fields dl dd,
#mastodon .account__header__account-note dl dd,
.public-layout .account__header__account-note dl dd,
#mastodon
  .account__header__fields.account__header__account-note.account__header__account-note
  dd,
.public-layout
  .account__header__fields.account__header__account-note.account__header__account-note
  dd,
#mastodon
  .account__header__account-note.account__header__account-note.account__header__account-note
  dd,
.public-layout
  .account__header__account-note.account__header__account-note.account__header__account-note
  dd {
  width: unset;
  padding-block: 0.5em;
}

#mastodon .account__header__fields dl dt,
.public-layout .account__header__fields dl dt,
#mastodon .account__header__account-note dl dt,
.public-layout .account__header__account-note dl dt,
#mastodon
  .account__header__fields.account__header__account-note.account__header__account-note
  dt,
.public-layout
  .account__header__fields.account__header__account-note.account__header__account-note
  dt,
#mastodon
  .account__header__account-note.account__header__account-note.account__header__account-note
  dt,
.public-layout
  .account__header__account-note.account__header__account-note.account__header__account-note
  dt {
  background: none;
}

#mastodon .account__header__fields dl dd,
.public-layout .account__header__fields dl dd,
#mastodon .account__header__account-note dl dd,
.public-layout .account__header__account-note dl dd,
#mastodon
  .account__header__fields.account__header__account-note.account__header__account-note
  dd,
.public-layout
  .account__header__fields.account__header__account-note.account__header__account-note
  dd,
#mastodon
  .account__header__account-note.account__header__account-note.account__header__account-note
  dd,
.public-layout
  .account__header__account-note.account__header__account-note.account__header__account-note
  dd {
  border: 0;
}

#mastodon .account__header__fields dl dd:not(.verified),
.public-layout .account__header__fields dl dd:not(.verified),
#mastodon .account__header__account-note dl dd:not(.verified),
.public-layout .account__header__account-note dl dd:not(.verified),
#mastodon
  .account__header__fields.account__header__account-note.account__header__account-note
  dd:not(.verified),
.public-layout
  .account__header__fields.account__header__account-note.account__header__account-note
  dd:not(.verified),
#mastodon
  .account__header__account-note.account__header__account-note.account__header__account-note
  dd:not(.verified),
.public-layout
  .account__header__account-note.account__header__account-note.account__header__account-note
  dd:not(.verified) {
  padding-inline-start: 0;
}

#mastodon .account__header__fields.account__header__account-note,
.public-layout .account__header__fields.account__header__account-note,
#mastodon .account__header__account-note.account__header__account-note,
.public-layout .account__header__account-note.account__header__account-note {
  margin: 10px;

  // margin-bottom: 0;
  gap: 0;
  background: none !important;
  border: 1px solid var(--border-color) !important;
}

#mastodon .account__header__fields textarea,
.public-layout .account__header__fields textarea,
#mastodon .account__header__account-note textarea,
.public-layout .account__header__account-note textarea {
  width: 0;
  flex-grow: 1;
  margin: 0 !important;
  border-radius: 0;
}

#mastodon .account__header__fields label,
.public-layout .account__header__fields label,
#mastodon .account__header__account-note label,
.public-layout .account__header__account-note label {
  margin: 0;
  font-weight: 600;
  padding-inline: 14px;
}

#mastodon#mastodon .account__section-headline,
.public-layout#mastodon .account__section-headline {
  background: none;
}

.with-modals #mastodon#mastodon .account__section-headline,
.with-modals .public-layout#mastodon .account__section-headline {
  border: 0 !important;
}

#mastodon#mastodon .account__section-headline a,
.public-layout#mastodon .account__section-headline a,
#mastodon#mastodon .account__section-headline button,
.public-layout#mastodon .account__section-headline button {
  background: none;
  border-radius: 0 !important;
}

#mastodon#mastodon .account__section-headline a span,
.public-layout#mastodon .account__section-headline a span,
#mastodon#mastodon .account__section-headline button span,
.public-layout#mastodon .account__section-headline button span {
  font-weight: 400;
}

#mastodon#mastodon .account__section-headline a.active span,
.public-layout#mastodon .account__section-headline a.active span,
#mastodon#mastodon .account__section-headline button.active span,
.public-layout#mastodon .account__section-headline button.active span {
  font-weight: 700;
}

#mastodon .notification__filter-bar,
.public-layout .notification__filter-bar {
  border: 0 !important;
  margin-bottom: calc(0px - var(--radius));
  padding-bottom: var(--radius);
}

#mastodon .focusable,
.public-layout .focusable,
#mastodon .entry,
.public-layout .entry,
#mastodon .statuses-grid__item .detailed-status,
.public-layout .statuses-grid__item .detailed-status {
  overflow: clip;
  border-radius: var(--radius) !important;
  transition:
    background 0.2s,
    box-shadow 0.4s,
    margin 0.2s,
    border 0.2s;
  animation: slide-up 0.4s forwards cubic-bezier(0, 1, 1, 1);
  position: relative;
}

#mastodon .focusable.focusable,
.public-layout .focusable.focusable,
#mastodon .entry.focusable,
.public-layout .entry.focusable,
#mastodon .statuses-grid__item .detailed-status.focusable,
.public-layout .statuses-grid__item .detailed-status.focusable {
  background: none;
}

#mastodon .focusable.entry,
.public-layout .focusable.entry,
#mastodon .entry.entry,
.public-layout .entry.entry,
#mastodon .statuses-grid__item .detailed-status.entry,
.public-layout .statuses-grid__item .detailed-status.entry {
  margin-bottom: 10px;
}

#mastodon .focusable.entry .status,
.public-layout .focusable.entry .status,
#mastodon .entry.entry .status,
.public-layout .entry.entry .status,
#mastodon .statuses-grid__item .detailed-status.entry .status,
.public-layout .statuses-grid__item .detailed-status.entry .status {
  border: 0 !important;
}

#mastodon .focusable:not(.detailed-status__wrapper)::after,
.public-layout .focusable:not(.detailed-status__wrapper)::after,
#mastodon .entry:not(.detailed-status__wrapper)::after,
.public-layout .entry:not(.detailed-status__wrapper)::after,
#mastodon
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper)::after,
.public-layout
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: var(--radius);
  right: var(--radius);
  border-top: 1px solid var(--border-color);
}

#mastodon .focusable:not(.detailed-status__wrapper)::before,
.public-layout .focusable:not(.detailed-status__wrapper)::before,
#mastodon .entry:not(.detailed-status__wrapper)::before,
.public-layout .entry:not(.detailed-status__wrapper)::before,
#mastodon
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper)::before,
.public-layout
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper)::before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  bottom: 1px;
  pointer-events: none;
  transition: background-color 0.2s;
}

#mastodon .focusable:not(.detailed-status__wrapper):hover::before,
.public-layout .focusable:not(.detailed-status__wrapper):hover::before,
#mastodon .entry:not(.detailed-status__wrapper):hover::before,
.public-layout .entry:not(.detailed-status__wrapper):hover::before,
#mastodon
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper):hover::before,
.public-layout
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper):hover::before,
#mastodon .focusable:not(.detailed-status__wrapper):focus-within::before,
.public-layout .focusable:not(.detailed-status__wrapper):focus-within::before,
#mastodon .entry:not(.detailed-status__wrapper):focus-within::before,
.public-layout .entry:not(.detailed-status__wrapper):focus-within::before,
#mastodon
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper):focus-within::before,
.public-layout
  .statuses-grid__item
  .detailed-status:not(.detailed-status__wrapper):focus-within::before {
  background-color: var(--hover-color);
}

#mastodon .status,
.public-layout .status {
  overflow: visible !important;
  padding: 15px !important;

  // padding-inline-start: 78px !important;
}

#mastodon .status__prepend,
.public-layout .status__prepend,
#mastodon .notification__message,
.public-layout .notification__message {
  padding-top: 15px !important;
  padding-bottom: 0 !important;

  // margin-inline-start: 78px;
  margin-bottom: -10px !important;
  z-index: 2;
}

#mastodon .account__avatar-overlay-base,
.public-layout .account__avatar-overlay-base {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: var(--radius);
}

#mastodon .account__avatar-overlay-overlay,
.public-layout .account__avatar-overlay-overlay {
  border-radius: var(--radius-round);
  bottom: 0;
  right: -10%;
}

#mastodon .status__info,
.public-layout .status__info {
  margin-top: 5px;
}

#mastodon .status__info .status__avatar,
.public-layout .status__info .status__avatar {
  top: 15px;
  left: 15px !important;
  right: 15px;
  z-index: 2;
}

#mastodon .status__info .display-name strong,
.public-layout .status__info .display-name strong {
  font-weight: 600;
}

#mastodon .status__content,
.public-layout .status__content {
  min-height: 1.4em;
  padding-top: 2px;
  margin-inline: -100px;
  padding-inline: 100px;
  text-align: unset !important;
}

#mastodon .status__content.status__content--with-spoiler > p:first-child,
.public-layout .status__content.status__content--with-spoiler > p:first-child {
  margin-bottom: 0;
}

.rtl #mastodon .status__content,
.rtl .public-layout .status__content {
  text-align: right !important;
}

#mastodon .status__content__spoiler-link,
.public-layout .status__content__spoiler-link {
  display: block;
  padding: 1em;
  color: inherit;
  font-weight: 700;
  background: none;
  margin-inline-start: -100px !important;
  padding-inline-start: 100px !important;
  width: calc(100% + 150px);
  text-align: unset;
  font-size: 0.8em;
}

#mastodon .status__content__spoiler-link:hover,
.public-layout .status__content__spoiler-link:hover,
#mastodon .status__content__spoiler-link:focus,
.public-layout .status__content__spoiler-link:focus {
  background-color: var(--hover-color);
}

#mastodon .media-gallery,
.public-layout .media-gallery,
#mastodon .video-player,
.public-layout .video-player,
#mastodon .status-card.horizontal.interactive,
.public-layout .status-card.horizontal.interactive,
#mastodon .status-card,
.public-layout .status-card,
#mastodon .audio-player,
.public-layout .audio-player {
  // margin-inline-start: -63px;
  width: unset !important;
  box-shadow: var(--shadow-low);
  border-radius: var(--radius);
  margin-block: 15px !important;
  animation: scale-in 0.4s;
  max-width: unset !important;
  max-height: 80vh !important;
  direction: unset;
}

#mastodon .media-gallery.status-card,
.public-layout .media-gallery.status-card,
#mastodon .video-player.status-card,
.public-layout .video-player.status-card,
#mastodon .status-card.horizontal.interactive.status-card,
.public-layout .status-card.horizontal.interactive.status-card,
#mastodon .status-card.status-card,
.public-layout .status-card.status-card,
#mastodon .audio-player.status-card,
.public-layout .audio-player.status-card {
  box-shadow: var(--shadow);
  box-shadow: none;
}

#mastodon .media-gallery.status-card .status-card__content,
.public-layout .media-gallery.status-card .status-card__content,
#mastodon .video-player.status-card .status-card__content,
.public-layout .video-player.status-card .status-card__content,
#mastodon .status-card.horizontal.interactive.status-card .status-card__content,
.public-layout
  .status-card.horizontal.interactive.status-card
  .status-card__content,
#mastodon .status-card.status-card .status-card__content,
.public-layout .status-card.status-card .status-card__content,
#mastodon .audio-player.status-card .status-card__content,
.public-layout .audio-player.status-card .status-card__content {
  padding-inline: 12px;
}

#mastodon .media-gallery.status-card:hover,
.public-layout .media-gallery.status-card:hover,
#mastodon .video-player.status-card:hover,
.public-layout .video-player.status-card:hover,
#mastodon .status-card.horizontal.interactive.status-card:hover,
.public-layout .status-card.horizontal.interactive.status-card:hover,
#mastodon .status-card.status-card:hover,
.public-layout .status-card.status-card:hover,
#mastodon .audio-player.status-card:hover,
.public-layout .audio-player.status-card:hover {
  background-color: var(--hover-color);
}

#mastodon .media-gallery__item:not([style*='50%']),
.public-layout .media-gallery__item:not([style*='50%']) {
  border-radius: var(--radius);
}

#mastodon .detailed-status,
.public-layout .detailed-status {
  padding: 15px !important;
  padding-bottom: 5px !important;
}

#mastodon .detailed-status__wrapper,
.public-layout .detailed-status__wrapper,
#mastodon .detailed-status,
.public-layout .detailed-status,
#mastodon .picture-in-picture,
.public-layout .picture-in-picture {
  box-shadow: var(--shadow);
}

#mastodon .detailed-status__wrapper.detailed-status__wrapper,
.public-layout .detailed-status__wrapper.detailed-status__wrapper,
#mastodon .detailed-status.detailed-status__wrapper,
.public-layout .detailed-status.detailed-status__wrapper,
#mastodon .picture-in-picture.detailed-status__wrapper,
.public-layout .picture-in-picture.detailed-status__wrapper {
  margin-block: 15px;
}

#mastodon .detailed-status__wrapper .media-gallery,
.public-layout .detailed-status__wrapper .media-gallery,
#mastodon .detailed-status .media-gallery,
.public-layout .detailed-status .media-gallery,
#mastodon .picture-in-picture .media-gallery,
.public-layout .picture-in-picture .media-gallery,
#mastodon .detailed-status__wrapper .video-player,
.public-layout .detailed-status__wrapper .video-player,
#mastodon .detailed-status .video-player,
.public-layout .detailed-status .video-player,
#mastodon .picture-in-picture .video-player,
.public-layout .picture-in-picture .video-player,
#mastodon .detailed-status__wrapper .status-card.horizontal.interactive,
.public-layout .detailed-status__wrapper .status-card.horizontal.interactive,
#mastodon .detailed-status .status-card.horizontal.interactive,
.public-layout .detailed-status .status-card.horizontal.interactive,
#mastodon .picture-in-picture .status-card.horizontal.interactive,
.public-layout .picture-in-picture .status-card.horizontal.interactive,
#mastodon .detailed-status__wrapper .status-card,
.public-layout .detailed-status__wrapper .status-card,
#mastodon .detailed-status .status-card,
.public-layout .detailed-status .status-card,
#mastodon .picture-in-picture .status-card,
.public-layout .picture-in-picture .status-card,
#mastodon .detailed-status__wrapper .audio-player,
.public-layout .detailed-status__wrapper .audio-player,
#mastodon .detailed-status .audio-player,
.public-layout .detailed-status .audio-player,
#mastodon .picture-in-picture .audio-player,
.public-layout .picture-in-picture .audio-player {
  margin-inline: 0 !important;
  max-height: unset !important;
}

#mastodon .detailed-status__wrapper .status__content,
.public-layout .detailed-status__wrapper .status__content,
#mastodon .detailed-status .status__content,
.public-layout .detailed-status .status__content,
#mastodon .picture-in-picture .status__content,
.public-layout .picture-in-picture .status__content {
  min-height: unset !important;
}

#mastodon .picture-in-picture .picture-in-picture__header,
.public-layout .picture-in-picture .picture-in-picture__header {
  border-radius: var(--radius) var(--radius) 0 0;
}

#mastodon .picture-in-picture .media-gallery,
.public-layout .picture-in-picture .media-gallery,
#mastodon .picture-in-picture .video-player,
.public-layout .picture-in-picture .video-player,
#mastodon .picture-in-picture .status-card.horizontal.interactive,
.public-layout .picture-in-picture .status-card.horizontal.interactive,
#mastodon .picture-in-picture .status-card,
.public-layout .picture-in-picture .status-card,
#mastodon .picture-in-picture .audio-player,
.public-layout .picture-in-picture .audio-player {
  margin: 0 !important;
  border-radius: 0 !important;
}

#mastodon .picture-in-picture .picture-in-picture__footer,
.public-layout .picture-in-picture .picture-in-picture__footer {
  border-radius: 0 0 var(--radius) var(--radius);
}

#mastodon .status__action-bar .icon-button,
.public-layout .status__action-bar .icon-button,
#mastodon .detailed-status__action-bar .icon-button,
.public-layout .detailed-status__action-bar .icon-button,
#mastodon .picture-in-picture__footer .icon-button,
.public-layout .picture-in-picture__footer .icon-button {
  display: inline-flex;
  align-items: center;

  // width: unset !important;
  padding: 0.3em !important;
  height: unset !important;
  flex: 0 1 auto !important;
  min-width: 0;
  border-radius: var(--radius);
  justify-content: center;
  gap: 4px;
  width: 100% !important;
}

#mastodon .status__action-bar .icon-button:last-child,
.public-layout .status__action-bar .icon-button:last-child,
#mastodon .detailed-status__action-bar .icon-button:last-child,
.public-layout .detailed-status__action-bar .icon-button:last-child,
#mastodon .picture-in-picture__footer .icon-button:last-child,
.public-layout .picture-in-picture__footer .icon-button:last-child {
  margin: 0 !important;
}

#mastodon .status__action-bar .icon-button[aria-label]::after,
.public-layout .status__action-bar .icon-button[aria-label]::after,
#mastodon .detailed-status__action-bar .icon-button[aria-label]::after,
.public-layout .detailed-status__action-bar .icon-button[aria-label]::after,
#mastodon .picture-in-picture__footer .icon-button[aria-label]::after,
.public-layout .picture-in-picture__footer .icon-button[aria-label]::after {
  content: attr(aria-label);
  font-size: 0.7em;
  margin-inline-start: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#mastodon .status__action-bar .status__action-bar-dropdown,
.public-layout .status__action-bar .status__action-bar-dropdown,
#mastodon .detailed-status__action-bar .status__action-bar-dropdown,
.public-layout .detailed-status__action-bar .status__action-bar-dropdown,
#mastodon .picture-in-picture__footer .status__action-bar-dropdown,
.public-layout .picture-in-picture__footer .status__action-bar-dropdown,
#mastodon .status__action-bar .detailed-status__action-bar-dropdown,
.public-layout .status__action-bar .detailed-status__action-bar-dropdown,
#mastodon .detailed-status__action-bar .detailed-status__action-bar-dropdown,
.public-layout
  .detailed-status__action-bar
  .detailed-status__action-bar-dropdown,
#mastodon .picture-in-picture__footer .detailed-status__action-bar-dropdown,
.public-layout
  .picture-in-picture__footer
  .detailed-status__action-bar-dropdown,
#mastodon .status__action-bar .detailed-status__button,
.public-layout .status__action-bar .detailed-status__button,
#mastodon .detailed-status__action-bar .detailed-status__button,
.public-layout .detailed-status__action-bar .detailed-status__button,
#mastodon .picture-in-picture__footer .detailed-status__button,
.public-layout .picture-in-picture__footer .detailed-status__button {
  display: contents;
}

#mastodon .status__action-bar.detailed-status__action-bar,
.public-layout .status__action-bar.detailed-status__action-bar,
#mastodon .detailed-status__action-bar.detailed-status__action-bar,
.public-layout .detailed-status__action-bar.detailed-status__action-bar,
#mastodon .picture-in-picture__footer.detailed-status__action-bar,
.public-layout .picture-in-picture__footer.detailed-status__action-bar,
#mastodon .status__action-bar.picture-in-picture__footer,
.public-layout .status__action-bar.picture-in-picture__footer,
#mastodon .detailed-status__action-bar.picture-in-picture__footer,
.public-layout .detailed-status__action-bar.picture-in-picture__footer,
#mastodon .picture-in-picture__footer.picture-in-picture__footer,
.public-layout .picture-in-picture__footer.picture-in-picture__footer {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

#mastodon .status__action-bar.detailed-status__action-bar .icon-button,
.public-layout .status__action-bar.detailed-status__action-bar .icon-button,
#mastodon .detailed-status__action-bar.detailed-status__action-bar .icon-button,
.public-layout
  .detailed-status__action-bar.detailed-status__action-bar
  .icon-button,
#mastodon .picture-in-picture__footer.detailed-status__action-bar .icon-button,
.public-layout
  .picture-in-picture__footer.detailed-status__action-bar
  .icon-button,
#mastodon .status__action-bar.picture-in-picture__footer .icon-button,
.public-layout .status__action-bar.picture-in-picture__footer .icon-button,
#mastodon .detailed-status__action-bar.picture-in-picture__footer .icon-button,
.public-layout
  .detailed-status__action-bar.picture-in-picture__footer
  .icon-button,
#mastodon .picture-in-picture__footer.picture-in-picture__footer .icon-button,
.public-layout
  .picture-in-picture__footer.picture-in-picture__footer
  .icon-button {
  flex-grow: 1 !important;
  justify-content: center;
}

#mastodon .status__action-bar.picture-in-picture__footer .icon-button::after,
.public-layout
  .status__action-bar.picture-in-picture__footer
  .icon-button::after,
#mastodon
  .detailed-status__action-bar.picture-in-picture__footer
  .icon-button::after,
.public-layout
  .detailed-status__action-bar.picture-in-picture__footer
  .icon-button::after,
#mastodon
  .picture-in-picture__footer.picture-in-picture__footer
  .icon-button::after,
.public-layout
  .picture-in-picture__footer.picture-in-picture__footer
  .icon-button::after {
  content: unset !important;
}

#mastodon .tabs-bar__wrapper,
.public-layout .tabs-bar__wrapper,
#mastodon .column-back-button--slim .column-back-button,
.public-layout .column-back-button--slim .column-back-button {
  transition: margin 0.2s cubic-bezier(0, 0, 0, 1.1);
}

.column-header {
  font-weight: 600;
}

.column-header,
.column-header button {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.column-header ~ .scrollable {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
@media (width >= 1191px) {
  #mastodon .tabs-bar__wrapper,
  .public-layout .tabs-bar__wrapper,
  #mastodon .column-back-button--slim .column-back-button,
  .public-layout .column-back-button--slim .column-back-button {
    position: fixed;
    width: 285px;
    margin-inline-start: calc(var(--tl-width) - 15px);
    top: 0 !important;
    left: unset !important;
    right: unset !important;
    min-height: 48px !important;
    margin-top: 30px;
    overflow: clip;
    border-radius: var(--radius) var(--radius) !important;
    box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 10%);
  }

  #mastodon .tabs-bar__wrapper:not(.column-back-button),
  .public-layout .tabs-bar__wrapper:not(.column-back-button),
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button),
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button) {
    padding-top: 0;
  }

  #mastodon .tabs-bar__wrapper .column-header,
  .public-layout .tabs-bar__wrapper .column-header,
  #mastodon .column-back-button--slim .column-back-button .column-header,
  .public-layout .column-back-button--slim .column-back-button .column-header,
  #mastodon .tabs-bar__wrapper .column-header__buttons button,
  .public-layout .tabs-bar__wrapper .column-header__buttons button,
  #mastodon
    .column-back-button--slim
    .column-back-button
    .column-header__buttons
    button,
  .public-layout
    .column-back-button--slim
    .column-back-button
    .column-header__buttons
    button {
    background: none !important;
  }

  #mastodon .tabs-bar__wrapper .announcements,
  .public-layout .tabs-bar__wrapper .announcements,
  #mastodon .column-back-button--slim .column-back-button .announcements,
  .public-layout .column-back-button--slim .column-back-button .announcements {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  #mastodon .tabs-bar__wrapper .announcements__container,
  .public-layout .tabs-bar__wrapper .announcements__container,
  #mastodon
    .column-back-button--slim
    .column-back-button
    .announcements__container,
  .public-layout
    .column-back-button--slim
    .column-back-button
    .announcements__container {
    width: 100% !important;
  }

  #mastodon .tabs-bar__wrapper .announcements__mastodon,
  .public-layout .tabs-bar__wrapper .announcements__mastodon,
  #mastodon
    .column-back-button--slim
    .column-back-button
    .announcements__mastodon,
  .public-layout
    .column-back-button--slim
    .column-back-button
    .announcements__mastodon {
    margin-inline-start: 10px;
    z-index: -1;
    position: relative;
  }
}
@media (1191px >= width >= 895px) {
  #mastodon .tabs-bar__wrapper.column-back-button,
  .public-layout .tabs-bar__wrapper.column-back-button,
  #mastodon .column-back-button--slim .column-back-button.column-back-button,
  .public-layout
    .column-back-button--slim
    .column-back-button.column-back-button {
    border-radius: 0 !important;
  }

  #mastodon .tabs-bar__wrapper:not(.column-back-button),
  .public-layout .tabs-bar__wrapper:not(.column-back-button),
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button),
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button) {
    padding-top: 0;
    top: 0;
  }

  #mastodon .tabs-bar__wrapper:not(.column-back-button) .tabs-bar,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) .tabs-bar,
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    .tabs-bar,
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    .tabs-bar {
    margin-bottom: 0;
    border: 0 !important;
  }

  #mastodon .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal,
  .public-layout .tabs-bar__wrapper:not(.column-back-button) #tabs-bar__portal,
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    #tabs-bar__portal,
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    #tabs-bar__portal {
    border-radius: 0 !important;
  }

  #mastodon
    .tabs-bar__wrapper:not(.column-back-button)
    #tabs-bar__portal
    .column-header,
  .public-layout
    .tabs-bar__wrapper:not(.column-back-button)
    #tabs-bar__portal
    .column-header,
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    #tabs-bar__portal
    .column-header,
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    #tabs-bar__portal
    .column-header,
  #mastodon
    .tabs-bar__wrapper:not(.column-back-button)
    #tabs-bar__portal
    button,
  .public-layout
    .tabs-bar__wrapper:not(.column-back-button)
    #tabs-bar__portal
    button,
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    #tabs-bar__portal
    button,
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    #tabs-bar__portal
    button {
    border-radius: 0 !important;
  }

  #mastodon
    .tabs-bar__wrapper:not(.column-back-button)
    ~ .columns-area
    .scrollable,
  .public-layout
    .tabs-bar__wrapper:not(.column-back-button)
    ~ .columns-area
    .scrollable,
  #mastodon
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    ~ .columns-area
    .scrollable,
  .public-layout
    .column-back-button--slim
    .column-back-button:not(.column-back-button)
    ~ .columns-area
    .scrollable {
    border-radius: 0 !important;
  }

  #mastodon .columns-area__panels__main,
  #mastodon .columns-area__panels__main .columns-area {
    padding-top: 0 !important;
  }

  #mastodon .columns-area__panels__main > div,
  #mastodon .columns-area__panels__main .scrollable {
    border-radius: 0 !important;
  }

  .columns-area__panels__pane__inner {
    margin-top: 0 !important;
  }
}

// (min-width: 1191px) and (min-width: 1320px)
@media (1320px >= width >= 1191px) {
  #mastodon .tabs-bar__wrapper,
  .public-layout .tabs-bar__wrapper,
  #mastodon .column-back-button--slim .column-back-button,
  .public-layout .column-back-button--slim .column-back-button {
    margin-inline-start: calc(var(--tl-width) - 5px);
  }
}

#mastodon .navigation-panel,
.public-layout .navigation-panel {
  padding-top: 50px;
  padding-bottom: 20px;

  // height: calc(100% - 30px);
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 0;
  }
}

#mastodon .column-link,
.public-layout .column-link {
  flex-grow: 2 !important;
  display: flex !important;
  align-items: center !important;
  align-content: center;
  max-height: 3.3em;
  min-height: 2.7em !important;
  height: 52px;
  padding-block: 0;
  position: relative;
  box-sizing: border-box;
  opacity: 0.9;
}

#mastodon .column-link .column-link__icon,
.public-layout .column-link .column-link__icon,
.column-header__icon {
  margin-inline-end: 1em !important;
  margin-right: 0;
}

.column-header__icon {
  margin-inline-end: 1em !important;
}

#mastodon .column-link::before,
.public-layout .column-link::before {
  content: '' !important;
  position: absolute;
  border-radius: 100px !important;
  width: unset !important;
  height: unset !important;
  inset: 0 !important;
  opacity: 0 !important;
  background-color: rgba(150, 150, 150, 10%);
  transition: opacity 0.2s;
}

#mastodon .column-link.active,
.public-layout .column-link.active {
  opacity: 1 !important;
  font-weight: 600;
}

#mastodon .column-link:hover::before,
.public-layout .column-link:hover::before,
#mastodon .column-link:focus::before,
.public-layout .column-link:focus::before {
  opacity: 1 !important;
}

#mastodon .getting-started__trends .trends__item__current,
.public-layout .getting-started__trends .trends__item__current {
  display: none;
}

#mastodon .getting-started__trends .trends__item__sparkline path:first-child,
.public-layout
  .getting-started__trends
  .trends__item__sparkline
  path:first-child {
  display: none;
}

#mastodon .getting-started__trends .trends__item__sparkline path:last-child,
.public-layout
  .getting-started__trends
  .trends__item__sparkline
  path:last-child {
  mask: linear-gradient(to left, #000, #000, transparent);
}

#mastodon .getting-started,
.public-layout .getting-started {
  padding: 5px 10px;
  border: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#mastodon .getting-started .flex-spacer,
.public-layout .getting-started .flex-spacer {
  display: none;
}

#mastodon .getting-started .getting-started__wrapper,
.public-layout .getting-started .getting-started__wrapper {
  background: none;
  height: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#mastodon .getting-started .getting-started__wrapper > *,
.public-layout .getting-started .getting-started__wrapper > * {
  box-sizing: border-box;
}

#mastodon .getting-started .getting-started__wrapper .navigation-bar,
.public-layout .getting-started .getting-started__wrapper .navigation-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
  margin: 20px;
  margin-bottom: 5px;
  position: relative;
}

#mastodon .getting-started .getting-started__wrapper .navigation-bar > *,
.public-layout .getting-started .getting-started__wrapper .navigation-bar > * {
  flex-grow: 0;
}

#mastodon .getting-started .getting-started__wrapper .navigation-bar > a,
.public-layout .getting-started .getting-started__wrapper .navigation-bar > a {
  flex-grow: 1;
  max-height: 126px;
  margin-bottom: 10px;
}

#mastodon .getting-started .getting-started__wrapper .navigation-bar__profile,
.public-layout
  .getting-started
  .getting-started__wrapper
  .navigation-bar__profile {
  font-size: 1.2em;
  line-height: 1.4;
  margin: 10px 0;
}

#mastodon
  .getting-started
  .getting-started__wrapper
  .navigation-bar__profile
  .permalink,
.public-layout
  .getting-started
  .getting-started__wrapper
  .navigation-bar__profile
  .permalink {
  font-size: 1.2em;
}

#mastodon .getting-started .getting-started__wrapper .navigation-bar__actions,
.public-layout
  .getting-started
  .getting-started__wrapper
  .navigation-bar__actions {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: auto !important;
}

#mastodon
  .getting-started
  .getting-started__wrapper
  .navigation-bar__actions
  .icon-button,
.public-layout
  .getting-started
  .getting-started__wrapper
  .navigation-bar__actions
  .icon-button {
  width: auto !important;
  height: auto !important;
  padding: 20px;
}

#mastodon
  .getting-started
  .getting-started__wrapper
  .navigation-bar__actions
  .icon-button
  i,
.public-layout
  .getting-started
  .getting-started__wrapper
  .navigation-bar__actions
  .icon-button
  i {
  font-size: 20px;
}

#mastodon .getting-started .getting-started__wrapper .account__avatar,
.public-layout .getting-started .getting-started__wrapper .account__avatar {
  width: 126px !important;
  height: 126px !important;
  min-height: 100%;
  flex-grow: 1;
  background-size: contain !important;
  background-position: left;

  /* border-radius: 0 !important; */
}

.rtl #mastodon .getting-started .getting-started__wrapper .account__avatar,
.rtl
  .public-layout
  .getting-started
  .getting-started__wrapper
  .account__avatar {
  background-position: right !important;
}

#mastodon .getting-started .getting-started__wrapper .account__avatar::before,
.public-layout
  .getting-started
  .getting-started__wrapper
  .account__avatar::before {
  content: '';
  background-image: inherit;
  position: absolute;
  background-size: cover;
  background-position: center;
  height: calc(100% + 100px);
  width: 200%;
  border-bottom-right-radius: 100%;
  transform: translate(-40%, -20%);
  filter: blur(100px);
  opacity: 0.2;
}

.rtl
  #mastodon
  .getting-started
  .getting-started__wrapper
  .account__avatar::before,
.rtl
  .public-layout
  .getting-started
  .getting-started__wrapper
  .account__avatar::before {
  transform: translate(40%, -20%);
}

#mastodon .getting-started .getting-started__wrapper > *,
.public-layout .getting-started .getting-started__wrapper > * {
  border: 0 !important;
  padding: 20px;
  background: none;
}

#mastodon .getting-started .getting-started__footer,
.public-layout .getting-started .getting-started__footer {
  padding-inline: 20px;
}

#mastodon .getting-started .getting-started__footer a span,
.public-layout .getting-started .getting-started__footer a span {
  font-size: 1.1em !important;
  line-height: 2;
}

#mastodon.public-layout .container:nth-child(2) > .grid .column-1,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1 {
  position: sticky;
  align-self: flex-end;
  bottom: 0;
  min-height: calc(100vh - 80px);
}

#mastodon.public-layout .container:nth-child(2) > .grid .column-1 > *,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1 > * {
  border-radius: var(--radius);
  overflow: hidden;
  border: 0 !important;
}

#mastodon.public-layout .container:nth-child(2) > .grid .column-1 a,
.public-layout.public-layout .container:nth-child(2) > .grid .column-1 a,
#mastodon.public-layout
  .container:nth-child(2)
  > .grid
  .column-1
  .hero-widget__text,
.public-layout.public-layout
  .container:nth-child(2)
  > .grid
  .column-1
  .hero-widget__text {
  border: 0 !important;
}
@media (width >= 416px) {
  #mastodon.public-layout .grid,
  .public-layout.public-layout .grid,
  #mastodon.public-layout .grid-4,
  .public-layout.public-layout .grid-4 {
    gap: 15px;
  }

  #mastodon.public-layout .header,
  .public-layout.public-layout .header {
    border-radius: var(--radius);
    margin: 15px 0;
  }

  #mastodon.public-layout .public-account-header,
  .public-layout.public-layout .public-account-header {
    border-radius: var(--radius);

    --border-color: transparent !important;

    border: 0 !important;
  }

  #mastodon.public-layout
    .public-account-header:not(.public-account-header--no-bar),
  .public-layout.public-layout
    .public-account-header:not(.public-account-header--no-bar) {
    margin-bottom: 15px;
  }

  #mastodon.public-layout
    .public-account-header
    .public-account-header__bar::before,
  .public-layout.public-layout
    .public-account-header
    .public-account-header__bar::before {
    border: 0;
  }

  #mastodon.public-layout .account__section-headline,
  .public-layout.public-layout .account__section-headline {
    border-radius: var(--radius) var(--radius) 0 0;
    border: 0;
  }

  #mastodon.public-layout
    .account__section-headline
    + .activity-stream
    > .entry:first-child,
  .public-layout.public-layout
    .account__section-headline
    + .activity-stream
    > .entry:first-child {
    border-radius: 0 !important;
  }

  #mastodon.public-layout .table-of-contents,
  .public-layout.public-layout .table-of-contents {
    max-height: calc(100vh - 20px);
    min-height: unset !important;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 10px;
  }
}
@media (width <= 416px) {
  #mastodon.public-layout .contact-widget,
  .public-layout.public-layout .contact-widget {
    padding: 0 10px;
  }

  #mastodon.public-layout .grid-4 > div:not(.column-4),
  .public-layout.public-layout .grid-4 > div:not(.column-4) {
    position: relative;
    z-index: 2;
  }

  #mastodon.public-layout .table-of-contents,
  .public-layout.public-layout .table-of-contents {
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px;
    box-sizing: border-box;
    overflow-x: scroll !important;
    scroll-snap-type: x mandatory;
    scroll-padding-inline-start: 30px;
    gap: 20px 30px;
    position: relative;
    background: none !important;
    border: 0 !important;
    scroll-behavior: smooth;
  }

  #mastodon.public-layout .table-of-contents > li,
  .public-layout.public-layout .table-of-contents > li {
    width: 70%;
    max-height: 80%;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    scroll-snap-align: start;
    box-shadow: var(--shadow);
    transition:
      background-color 0.2s,
      box-shadow 0.2s;
  }

  #mastodon.public-layout .table-of-contents > li:hover,
  .public-layout.public-layout .table-of-contents > li:hover,
  #mastodon.public-layout .table-of-contents > li:focus-within,
  .public-layout.public-layout .table-of-contents > li:focus-within {
    background-color: var(--hover-color);
  }

  #mastodon.public-layout .table-of-contents > li ul,
  .public-layout.public-layout .table-of-contents > li ul {
    border: 0 !important;
  }

  #mastodon.public-layout .table-of-contents > li > a,
  .public-layout.public-layout .table-of-contents > li > a {
    border: 0 !important;
  }

  #mastodon.public-layout .table-of-contents > li:last-child,
  .public-layout.public-layout .table-of-contents > li:last-child {
    margin-inline-end: 70%;
  }
}

#mastodon.public-layout .account-card,
.public-layout.public-layout .account-card,
#mastodon.public-layout .card > a,
.public-layout.public-layout .card > a,
#mastodon.public-layout .page-header,
.public-layout.public-layout .page-header,
#mastodon.public-layout .statuses-grid__item,
.public-layout.public-layout .statuses-grid__item,
#mastodon.public-layout .box-widget,
.public-layout.public-layout .box-widget,
#mastodon.public-layout .directory__tag > a,
.public-layout.public-layout .directory__tag > a,
#mastodon.public-layout .hero-widget,
.public-layout.public-layout .hero-widget,
#mastodon.public-layout .landing-page__call-to-action,
.public-layout.public-layout .landing-page__call-to-action,
#mastodon.public-layout .table-of-contents,
.public-layout.public-layout .table-of-contents,
#mastodon.public-layout .rules-list,
.public-layout.public-layout .rules-list {
  border-radius: var(--radius);
  overflow: hidden;
}

#mastodon.public-layout .account-card__header,
.public-layout.public-layout .account-card__header {
  padding: 0 !important;
  padding-bottom: 4px !important;
}

#mastodon.public-layout .account-card__bio,
.public-layout.public-layout .account-card__bio {
  margin-top: 0 !important;
}

#mastodon.public-layout .account-card__actions,
.public-layout.public-layout .account-card__actions {
  margin-top: auto !important;
}

#mastodon.public-layout .statuses-grid,
.public-layout.public-layout .statuses-grid {
  margin: auto;
}

#mastodon.public-layout .entry,
.public-layout.public-layout .entry,
#mastodon.public-layout .entry ~ .entry,
.public-layout.public-layout .entry ~ .entry {
  margin: 0;
}

#mastodon.public-layout .entry:not(:last-child, :first-child),
.public-layout.public-layout .entry:not(:last-child, :first-child),
#mastodon.public-layout .entry ~ .entry:not(:last-child, :first-child),
.public-layout.public-layout .entry ~ .entry:not(:last-child, :first-child) {
  border-radius: 0 !important;
}

#mastodon.public-layout .entry:not(:first-child:last-child):last-child,
.public-layout.public-layout .entry:not(:first-child:last-child):last-child,
#mastodon.public-layout .entry ~ .entry:not(:first-child:last-child):last-child,
.public-layout.public-layout
  .entry
  ~ .entry:not(:first-child:last-child):last-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

#mastodon.public-layout .entry:not(:first-child:last-child):first-child,
.public-layout.public-layout .entry:not(:first-child:last-child):first-child,
#mastodon.public-layout
  .entry
  ~ .entry:not(:first-child:last-child):first-child,
.public-layout.public-layout
  .entry
  ~ .entry:not(:first-child:last-child):first-child {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#mastodon.public-layout .entry:last-child::before,
.public-layout.public-layout .entry:last-child::before {
  content: unset !important;
}

#mastodon.public-layout .entry::before,
.public-layout.public-layout .entry::before,
#mastodon.public-layout .entry::after,
.public-layout.public-layout .entry::after {
  bottom: 1px !important;
}

#mastodon.public-layout .status .status__content,
.public-layout.public-layout .status .status__content {
  margin-top: 0;
}

#mastodon.public-layout .detailed-status,
.public-layout.public-layout .detailed-status {
  padding: 20px !important;
  padding-bottom: 5px !important;
}

#mastodon.public-layout .detailed-status .status__content,
.public-layout.public-layout .detailed-status .status__content {
  margin-top: 0;
}

#mastodon.public-layout .detailed-status .detailed-status__meta,
.public-layout.public-layout .detailed-status .detailed-status__meta {
  margin-top: 15px;
  margin-bottom: 10px;
}

#mastodon.public-layout .footer .column-3,
.public-layout.public-layout .footer .column-3,
#mastodon.public-layout .footer .column-4,
.public-layout.public-layout .footer .column-4 {
  text-align: right;
}
@media (width <= 895px) {
  #mastodon .columns-area--mobile > .column,
  .public-layout .columns-area--mobile > .column {
    overflow: hidden !important;
  }

  #mastodon .columns-area--mobile > .column > div > div,
  .public-layout .columns-area--mobile > .column > div > div {
    animation: fade-up 0.4s;
  }

  #mastodon .columns-area--mobile > .column > .column-header__wrapper,
  .public-layout .columns-area--mobile > .column > .column-header__wrapper {
    display: none;
  }

  #mastodon .columns-area__panels__main,
  .public-layout .columns-area__panels__main {
    padding: 0 !important;
    max-width: unset;
  }

  #mastodon .columns-area__panels__main .scrollable,
  .public-layout .columns-area__panels__main .scrollable {
    padding: 5px !important;
    padding-bottom: 40vh !important;
  }

  #mastodon .columns-area__panels__main .scrollable::before,
  .public-layout .columns-area__panels__main .scrollable::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: inherit;
    z-index: -1;
  }

  #mastodon .columns-area__panels__main .scrollable .account-timeline__header,
  .public-layout
    .columns-area__panels__main
    .scrollable
    .account-timeline__header {
    margin: -5px !important;
  }

  #mastodon .is-composing .columns-area__panels__main,
  .public-layout .is-composing .columns-area__panels__main {
    padding-bottom: 40px !important;
  }

  #mastodon .columns-area__panels__main .scrollable,
  .public-layout .columns-area__panels__main .scrollable,
  #mastodon .columns-area__panels__main .account__header__image,
  .public-layout .columns-area__panels__main .account__header__image,
  #mastodon .columns-area__panels__main > div,
  .public-layout .columns-area__panels__main > div {
    border-radius: 0 !important;
  }

  #mastodon .status__prepend,
  .public-layout .status__prepend,
  #mastodon .notification__message,
  .public-layout .notification__message {
    margin-bottom: -5px !important;
  }

  #mastodon .status,
  .public-layout .status {
    padding-top: 14px !important;
  }

  #mastodon .status .status__avatar,
  .public-layout .status .status__avatar {
    left: 14px !important;
    width: 45px !important;
    height: 45px !important;
    background-size: 45px !important;
    flex-shrink: 0;
  }

  #mastodon .status .status__avatar > div,
  .public-layout .status .status__avatar > div {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
  }

  #mastodon .status .media-gallery,
  .public-layout .status .media-gallery,
  #mastodon .status .video-player,
  .public-layout .status .video-player,
  #mastodon .status .status-card.horizontal.interactive,
  .public-layout .status .status-card.horizontal.interactive,
  #mastodon .status .status-card,
  .public-layout .status .status-card,
  #mastodon .status .audio-player,
  .public-layout .status .audio-player {
    margin-bottom: 0 !important;
  }

  #mastodon .tabs-bar__wrapper,
  .public-layout .tabs-bar__wrapper {
    position: fixed !important;
    top: unset !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
    padding: 0;
    background: none;
    border-radius: 0 !important;
    pointer-events: none;
  }

  #mastodon .tabs-bar__wrapper > *,
  .public-layout .tabs-bar__wrapper > * {
    pointer-events: all;
  }

  #mastodon .tabs-bar__wrapper .tabs-bar,
  .public-layout .tabs-bar__wrapper .tabs-bar {
    order: 2;
    margin: 0;
    border: 0;
    border-radius: var(--radius-round) var(--radius-round) 0 0;
    color: #fff !important;
    overflow: visible !important;
    padding-inline: 5px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 10%);
  }

  .rtl #mastodon .tabs-bar__wrapper .tabs-bar,
  .rtl .public-layout .tabs-bar__wrapper .tabs-bar {
    flex-direction: row-reverse;
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a,
  .public-layout .tabs-bar__wrapper .tabs-bar a {
    flex-grow: 1 !important;
    flex-basis: unset !important;
    padding-block: 20px;
    position: relative;
    border: 0;
    background: none !important;
    transition:
      transform 0.4s cubic-bezier(0, 0, 0, 4),
      filter 0.1s;
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a > i,
  .public-layout .tabs-bar__wrapper .tabs-bar a > i {
    font-size: 18px;
    filter: opacity(0.7);
    transition: transform 0.2s cubic-bezier(0, 0, 0, 1.2);
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a > i .icon-with-badge__badge,
  .public-layout .tabs-bar__wrapper .tabs-bar a > i .icon-with-badge__badge {
    font-size: 0.5em;
    border-radius: var(--radius-round);
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a::before,
  .public-layout .tabs-bar__wrapper .tabs-bar a::before {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 5px;
    height: 10px;
    border-radius: 100px;
    background-color: currentColor;
    left: 50%;
    margin-inline-start: -2.5px;
    transition:
      bottom 0.2s cubic-bezier(0, 0, 0, 4),
      width 0.2s,
      margin 0.2s;
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a:active,
  .public-layout .tabs-bar__wrapper .tabs-bar a:active {
    transform: translateY(5px);
    transition: transform 1s;
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a.active,
  .public-layout .tabs-bar__wrapper .tabs-bar a.active {
    transform: translateY(-2px);
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a.active i,
  .public-layout .tabs-bar__wrapper .tabs-bar a.active i {
    filter: none !important;
  }

  #mastodon .tabs-bar__wrapper .tabs-bar a.active::before,
  .public-layout .tabs-bar__wrapper .tabs-bar a.active::before {
    bottom: -6px;
    width: 10px;
    margin-left: -5px;
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal {
    margin: 10px 20px !important;
    margin-right: auto !important;
    width: max-content !important;
    box-shadow: var(--shadow) !important;
    max-width: calc(100vw - 130px);
    border-radius: var(--radius-round) !important;
    overflow: hidden;
    transition: transform 0.2s;
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal > button,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal > button {
    padding-inline: 20px;
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal button,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal button {
    min-width: 60px;
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header > button,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header > button {
    padding-inline-start: 15px;
    transform-origin: center !important;
    padding: 15px !important;
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header {
    transition: transform 0.2s;
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header:active,
  .public-layout .tabs-bar__wrapper #tabs-bar__portal .column-header:active {
    transform: scale(0.98);
  }

  #mastodon .tabs-bar__wrapper #tabs-bar__portal .column-header__collapsible,
  .public-layout
    .tabs-bar__wrapper
    #tabs-bar__portal
    .column-header__collapsible {
    max-width: calc(100vw - 40px) !important;
    opacity: 1 !important;
  }

  .floating-action-button {
    bottom: 70px;
  }

  #mastodon .icon-button::after,
  .public-layout .icon-button::after {
    content: unset !important;
  }

  #mastodon .status__action-bar,
  .public-layout .status__action-bar {
    // margin-inline-start: -63px;
    margin-bottom: -10px;
    gap: 0;
  }

  #mastodon .status__action-bar .icon-button,
  .public-layout .status__action-bar .icon-button {
    flex-grow: 1 !important;
    margin: 0 !important;
    justify-content: center;
    padding: 0.7em !important;
  }

  #mastodon .modal-root__modal .status.light,
  .public-layout .modal-root__modal .status.light {
    overflow: hidden !important;
    padding-inline-start: 70px !important;
  }

  #mastodon .getting-started,
  .public-layout .getting-started {
    padding: 20px;
    padding-bottom: 60px;
  }

  #mastodon .getting-started__wrapper,
  .public-layout .getting-started__wrapper {
    flex-grow: 1;
    overflow: visible !important;
  }

  #mastodon .compose-form__autosuggest-wrapper,
  .public-layout .compose-form__autosuggest-wrapper {
    border: 1px solid var(--border-color);
  }

  .layout-single-column #mastodon .drawer__inner .navigation-bar {
    padding: 20px;
    padding-bottom: 10px;
  }

  .layout-single-column #mastodon .drawer__inner .compose-form {
    padding-bottom: 100px !important;
  }

  .layout-single-column
    #mastodon
    .drawer__inner
    .compose-form__autosuggest-wrapper {
    border-radius: var(--radius) !important;
    overflow: hidden;
  }

  .layout-single-column #mastodon .drawer__inner .compose-form__modifiers {
    padding-bottom: 50px;
  }

  .layout-single-column #mastodon .drawer__inner .compose-form__publish {
    margin-top: -45px;
    padding-inline: 8px;
  }

  .layout-single-column
    #mastodon
    .drawer__inner
    .compose-form__publish
    .compose-form__publish-button-wrapper {
    padding-top: 0;
  }

  .layout-single-column
    #mastodon
    .drawer__inner
    .compose-form__buttons-wrapper {
    position: fixed;
    bottom: 70px;
    border-radius: var(--radius-round) !important;
    left: 20px;
    right: 20px;
    padding-block: 0;
    z-index: 2;
  }

  .layout-single-column
    #mastodon
    .drawer__inner
    .compose-form__buttons-wrapper
    button {
    padding: 7px;
    border-radius: 0 !important;
  }

  .search-popout {
    max-width: unset !important;
    width: calc(100vw - 70px) !important;
    position: fixed;
    left: 15px;
  }

  #mastodon .columns-area__panels__pane__inner,
  .public-layout .columns-area__panels__pane__inner {
    margin-top: 0;
  }

  #mastodon .navigation-panel .column-link .column-link__icon,
  .public-layout .navigation-panel .column-link .column-link__icon {
    margin-inline-end: 0 !important;
  }
}

.layout-multiple-columns #mastodon .columns-area {
  overflow: auto hidden !important;
}

.layout-multiple-columns #mastodon .columns-area .column-header,
.layout-multiple-columns #mastodon .columns-area .scrollable,
.layout-multiple-columns #mastodon .columns-area .column-back-button,
.layout-multiple-columns #mastodon .columns-area .account__header__image {
  border-radius: 0 !important;
}

.layout-multiple-columns #mastodon .columns-area .icon-button::after {
  content: unset !important;
}

.layout-multiple-columns #mastodon .columns-area > div {
  border: 0 !important;
  padding: 0 !important;
}

.layout-multiple-columns
  #mastodon
  .columns-area
  > div:not(.drawer, :last-child) {
  padding-inline-end: 2px !important;
}

.layout-multiple-columns #mastodon .columns-area > div.column {
  flex-grow: 1;
  max-width: 600px;
}

.layout-multiple-columns #mastodon .columns-area > div:first-child {
  margin-inline-start: auto !important;
}

.layout-multiple-columns #mastodon .columns-area > div:last-child {
  margin-inline-end: auto !important;
}

.layout-multiple-columns #mastodon .columns-area .drawer {
  padding-inline: 10px !important;
  padding-top: 20px !important;
}

.layout-multiple-columns #mastodon .columns-area .drawer .drawer__header {
  border-radius: var(--radius-round);
  margin-inline: 5px;
  overflow: hidden;
  border: 0 !important;
}

.layout-multiple-columns #mastodon .columns-area .drawer .drawer__header a {
  border: 0;
}

.layout-multiple-columns
  #mastodon
  .columns-area
  .drawer
  .drawer__header
  a:first-child {
  padding-inline-start: 15px !important;
}

.layout-multiple-columns
  #mastodon
  .columns-area
  .drawer
  .drawer__header
  a:last-child {
  padding-inline-end: 15px !important;
}

.layout-multiple-columns #mastodon .columns-area .drawer__inner:not(.darker),
.layout-multiple-columns #mastodon .columns-area .drawer__inner__mastodon {
  background-color: transparent;
  border: 0 !important;
}

.layout-multiple-columns #mastodon .columns-area .drawer__inner.darker {
  border-radius: var(--radius) var(--radius) 0 0;
  margin-inline: 5px;
  width: calc(100% - 10px);
}

.layout-multiple-columns #mastodon .columns-area .getting-started__trends {
  padding: 0 20px;
}

.modal-root__container {
  animation: bounce-in 0.7s !important;
}
@media (prefers-reduced-motion: no-preference) {
  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }

    30% {
      transform: scale(0.99);
      opacity: 1;
    }

    60% {
      transform: scale(1.005);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }

    30% {
      transform: scale(0.99);
      opacity: 1;
    }

    60% {
      transform: scale(1.005);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }

    30% {
      transform: scale(0.99);
      opacity: 1;
    }

    60% {
      transform: scale(1.005);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }

    30% {
      transform: scale(0.99);
      opacity: 1;
    }

    60% {
      transform: scale(1.005);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes slide-up {
    from {
      transform: translateY(20px);
    }
  }
  @keyframes slide-up {
    from {
      transform: translateY(20px);
    }
  }
  @keyframes slide-up {
    from {
      transform: translateY(20px);
    }
  }
  @keyframes slide-up {
    from {
      transform: translateY(20px);
    }
  }
  @keyframes slide-up-big {
    from {
      transform: translateY(50vh);
    }
  }
  @keyframes slide-up-big {
    from {
      transform: translateY(50vh);
    }
  }
  @keyframes slide-up-big {
    from {
      transform: translateY(50vh);
    }
  }
  @keyframes slide-up-big {
    from {
      transform: translateY(50vh);
    }
  }
  @keyframes fade-up {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  @keyframes fade-up {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  @keyframes fade-up {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  @keyframes fade-up {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  @keyframes scale-in {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
  }
  @keyframes scale-in {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
  }
  @keyframes scale-in {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
  }
  @keyframes scale-in {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
  }
  @keyframes fade-left {
    from {
      transform: translateX(20px) opacity(0);
    }
  }
  @keyframes fade-left {
    from {
      transform: translateX(20px) opacity(0);
    }
  }
  @keyframes fade-left {
    from {
      transform: translateX(20px) opacity(0);
    }
  }
  @keyframes fade-left {
    from {
      transform: translateX(20px) opacity(0);
    }
  }
}

.layout-multiple-columns .column {
  flex-grow: 1;
  max-width: 500px;
}

.status__action-bar-button {
  &.icon-button--with-counter {
    margin-right: 0;
  }
}

.account__header__bio .account__header__fields {
  background: none;

  > dl {
    display: flex;
    gap: 12px;
    padding: 2px 12px;
  }
}

.status__action-bar {
  gap: 4px;
}

#tabs-bar__portal > button.column-back-button {
  font-weight: bold;
}

@media (width >= 1191px) {
  #tabs-bar__portal > button.column-back-button,
  .column-header__buttons > .column-header__back-button {
    background: none;
  }
}

.status--in-thread {
  > .status__action-bar {
    margin-inline-start: 0;
    width: 100%;
  }

  > .status__content__read-more-button {
    margin-inline-start: 0;
  }

  > .status__line {
    display: none;
  }
}

.navigation-panel {
  .column-link {
    border-left: none;
  }
}

.column-header__collapsible,
.notification__filter-bar {
  background: var(--surface-variant-background-color);
}

.notification-ungrouped {
  padding-left: 0;
  padding-right: 0;

  .status__action-bar,
  .status__content__read-more-button {
    margin-left: 0;
  }
}

.compose-form__highlightable {
  border-radius: var(--radius);
}

.tabs-bar__wrapper {
  backdrop-filter: none;
}

#mastodon .detailed-status .status__prepend,
.public-layout .detailed-status .status__prepend {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.admin {
  background: var(--background-color);
}
